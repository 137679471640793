// TODO: move this data in database
export default function Distributors() {
  return {
    default: {
      api: "https://diy.kurbhub.com/api/",
      name: "Curbulator",
      email_from: "curbulator@curbulator.com",
      email_to: "curbulator@mykurb.com",
      sales_email: "curbulator@mykurb.com", //sales@curbco.com
      design_email: "design@mykurb.com", // cc the sales@curbco.com
      phone: "281-456-4221",
      get_instant_pricing_url: "https://diy.kurbhub.com/Client",
      create_account_url: "https://diy.kurbhub.com/account/register",
      reset_account_url: "https://diy.kurbhub.com/account/recover",
      theme: {
        primary_color: "",
        btn_primary_color: "",
        btn_secondary_color: "",
        btn_success_color: "",
        btn_warning_color: "",
      },
    },
    curbco: {
      api: "https://curbco.kurbhub.com/api/",
      name: "Curbco",
      email_from: "curbulator@curbulator.com",
      email_to: "curbco@curbco.com",
      sales_email: "sales@curbco.com",
      design_email: "design@curbco.com",
      phone: "281-469-8855",
      get_instant_pricing_url: "https://curbco.kurbhub.com/Client",
      create_account_url: "https://curbco.kurbhub.com/account/register",
      reset_account_url: "https://curbco.kurbhub.com/account/recover",
      theme: {
        primary_color: "",
        btn_primary_color: "",
        btn_secondary_color: "",
        btn_success_color: "",
        btn_warning_color: "",
      },
    },
    primemetalproducts: {
      api: "https://primemetalproducts.kurbhub.com/api/",
      name: "Prime Metal",
      email_from: "prime@curbulator.com",
      email_to: "curbulator@primemetalproducts.com",
      sales_email: "orders@primemetalproducts.com",
      design_email: "orders@primemetalproducts.com",
      phone: "308-633-0025",
      get_instant_pricing_url: "https://primemetalproducts.kurbhub.com/",
      create_account_url:
        "https://primemetalproducts.kurbhub.com/account/registerselect",
      reset_account_url:
        "https://primemetalproducts.kurbhub.com/account/recover",
      theme: {
        primary_color: "",
        btn_primary_color: "",
        btn_secondary_color: "",
        btn_success_color: "",
        btn_warning_color: "",
      },
    },
    zingersheetmetal: {
      api: "https://zinger.kurbhub.com/api/",
      name: "Zinger Sheet Metal",
      email_from: "zingersheetmetal@curbulator.com",
      email_to: "estimating@zingersheetmetal.com",
      sales_email: "estimating@zingersheetmetal.com",
      design_email: "estimating@zingersheetmetal.com",
      phone: "616-532-3121",
      get_instant_pricing_url: "https://zinger.kurbhub.com/",
      create_account_url: "https://zinger.kurbhub.com/account/registerselect",
      reset_account_url: "https://zinger.kurbhub.com/account/recover",
      theme: {
        primary_color: "",
        btn_primary_color: "",
        btn_secondary_color: "",
        btn_success_color: "",
        btn_warning_color: "",
      },
    },
    omniduct: {
      api: "https://omniduct.kurbhub.com/api/",
      name: "OmniDuct",
      email_from: "omniduct@curbulator.com",
      email_to: "mikec@omniduct.com",
      sales_email: "mikec@omniduct.com",
      design_email: "mikec@omniduct.com",
      phone: "888-444-3828",
      get_instant_pricing_url: "https://omniduct.kurbhub.com/",
      create_account_url: "https://omniduct.kurbhub.com/account/registerselect",
      reset_account_url: "https://omniduct.kurbhub.com/account/recover",
      theme: {
        primary_color: "",
        btn_primary_color: "",
        btn_secondary_color: "",
        btn_success_color: "",
        btn_warning_color: "",
      },
    },
    g90mfg: {
      api: "https://g90.kurbhub.com/api/",
      name: "G90 Manufacturing",
      email_from: "g90mfg@curbulator.com",
      email_to: "joshua@g90mfg.com",
      sales_email: "joshua@g90mfg.com",
      design_email: "joshua@g90mfg.com",
      phone: "505-861-5883",
      get_instant_pricing_url: "https://g90.kurbhub.com/",
      create_account_url: "https://g90.kurbhub.com/account/registerselect",
      reset_account_url: "https://g90.kurbhub.com/account/recover",
      theme: {
        primary_color: "",
        btn_primary_color: "",
        btn_secondary_color: "",
        btn_success_color: "",
        btn_warning_color: "",
      },
    },
    roofcurbsystems: {
      api: "https://rcs.kurbhub.com/api/",
      name: "Roof Curb Systems",
      email_from: "roofcurbsystems@curbulator.com",
      email_to: "adapters@roofcurb.com",
      sales_email: "adapters@roofcurb.com",
      design_email: "adapters@roofcurb.com",
      phone: "586-872-7149",
      get_instant_pricing_url: "https://rcs.kurbhub.com/",
      create_account_url: "https://rcs.kurbhub.com/account/registerselect",
      reset_account_url: "https://rcs.kurbhub.com/account/recover",
      theme: {
        primary_color: "",
        btn_primary_color: "#c42031",
        btn_secondary_color: "#afb7ba",
        btn_success_color: "",
        btn_warning_color: "",
      },
    },
    hranec: {
      api: "https://hranec.kurbhub.com/api/",
      name: "HRANEC CORP.",
      email_from: "hranec@curbulator.com",
      email_to: "curbs@hranec.com",
      sales_email: "curbs@hranec.com",
      design_email: "curbs@hranec.com",
      phone: "724-437-2211",
      get_instant_pricing_url: "https://hranec.kurbhub.com/",
      create_account_url: "https://hranec.kurbhub.com/account/registerselect",
      reset_account_url: "https://hranec.kurbhub.com/account/recover",
      theme: {
        primary_color: "",
        btn_primary_color: "#c42031",
        btn_secondary_color: "#afb7ba",
        btn_success_color: "",
        btn_warning_color: "",
      },
    },
    kuckmechanical: {
      api: "https://kuckmechanical.kurbhub.com/api/",
      name: "Kuck Mechanical.",
      email_from: "kuckmechanical@curbulator.com",
      email_to: "sales@kuckmechanical.com",
      sales_email: "sales@kuckmechanical.com",
      design_email: "design@kuckmechanical.com",
      phone: "970-461-3553",
      get_instant_pricing_url: "https://kuckmechanical.kurbhub.com/",
      create_account_url:
        "https://kuckmechanical.kurbhub.com/account/registerselect",
      reset_account_url: "https://kuckmechanical.kurbhub.com/account/recover",
      theme: {
        primary_color: "",
        btn_primary_color: "#c42031",
        btn_secondary_color: "#afb7ba",
        btn_success_color: "",
        btn_warning_color: "",
      },
    },
    qualityduct: {
      api: "https://qualityduct.kurbhub.com/api/",
      name: "Quality Duct Supply",
      email_from: "qualityduct@curbulator.com",
      email_to: "quotes@qduct.net",
      sales_email: "quotes@qduct.net",
      design_email: "quotes@qduct.net",
      phone: "989-964-0557",
      get_instant_pricing_url: "https://qualityduct.kurbhub.com/",
      create_account_url:
        "https://qualityduct.kurbhub.com/account/registerselect",
      reset_account_url: "https://qualityduct.kurbhub.com/account/recover",
      theme: {
        primary_color: "",
        btn_primary_color: "#c42031",
        btn_secondary_color: "#afb7ba",
        btn_success_color: "",
        btn_warning_color: "",
      },
    },
  };
}
