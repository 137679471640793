import React, { useState, useEffect, useRef } from "react";
import useStore from "../../store/useStore";
import {
  totalCside,
  isCustom,
  isCsideModified,
  totalBgColor,
} from "../../helper";

const CSide = ({ orientation }) => {
  const {
    patternDetails,
    setCside,
    cSide,
    mSide,
    sSide,
    setSupplyLocation,
    searchKeyword,
  } = useStore((state) => ({
    patternDetails: state.patternDetails,
    setCside: state.setCside,
    cSide: state.cSide,
    mSide: state.mSide,
    sSide: state.sSide,
    setSupplyLocation: state.setSupplyLocation,
    searchKeyword: state.searchKeyword,
  }));

  const c1Val = useRef(0);
  const c2Val = useRef(0);
  const c3Val = useRef(0);
  const c4Val = useRef(0);
  const c5Val = useRef(0);
  const total = useRef(0);

  const [updated, setUpdated] = useState(0);

  const measurements = {
    c1Val: c1Val.current.value,
    c2Val: c2Val.current.value,
    c3Val: c3Val.current.value,
    c4Val: c4Val.current.value,
    c5Val: c5Val.current.value,
  };
  const isModified = isCsideModified(patternDetails, measurements);

  useEffect(() => {
    if (updated && isModified) {
      setCside({
        c1: c1Val.current.value || "",
        c2: c2Val.current.value || "",
        c3: c3Val.current.value || "",
        c4: c4Val.current.value || "",
        c5: c5Val.current.value || "",
      });
    } else if (updated && !isModified) {
      setCside(null);
      setSupplyLocation(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated, isModified]);

  // Update input values when patternDetails and cSide changes
  useEffect(() => {
    if (patternDetails && !cSide) {
      c1Val.current.value = patternDetails?.c1;
      c2Val.current.value = patternDetails?.c2;
      c3Val.current.value = patternDetails?.c3;
      c4Val.current.value = patternDetails?.c4;
      c5Val.current.value = patternDetails?.c5;
    } else if (cSide) {
      c1Val.current.value = cSide.c1;
      c2Val.current.value = cSide.c2;
      c3Val.current.value = cSide.c3;
      c4Val.current.value = cSide.c4;
      c5Val.current.value = cSide.c5;
    }

    total.current.textContent = totalCside(patternDetails, cSide);
  }, [patternDetails, cSide]);

  const classes =
    orientation === 2 || orientation === 4 ? "flex-col" : "flex-row";
  const colReverse = orientation === 4 ? "flex-col-reverse" : "";
  const rowReverse = orientation === 3 ? "flex-row-reverse" : "";
  const custom = isCustom(cSide, mSide, sSide);
  const orientationClass = `flex gap-1 md:gap-5 justify-center orientation-${orientation} ${classes} ${colReverse} ${rowReverse} h-full`;

  const totalBgColorClass = totalBgColor(
    searchKeyword,
    patternDetails,
    custom,
    total
  );

  return (
    <div id="c-side" className="h-full">
      <div className={orientationClass}>
        <div className="mfield field-1">
          <div className="shadow-md">
            <div className="font-semibold text-center border bg-stone-200">
              C1
            </div>
            <input
              className="p-2 border-0"
              type="text"
              onChange={() => setUpdated(updated + 1)}
              placeholder="0"
              ref={c1Val}
            />
          </div>
        </div>
        <div className="mfield field-2">
          <div className="shadow-md">
            <div className="font-semibold text-center border bg-stone-200">
              C2
            </div>
            <input
              className="p-2 border-0"
              type="text"
              onChange={() => setUpdated(updated + 1)}
              placeholder="0"
              ref={c2Val}
            />
          </div>
        </div>
        <div className="mfield field-3">
          <div className="shadow-md">
            <div className="font-semibold text-center border bg-stone-200">
              C3
            </div>
            <input
              className="p-2 border-0"
              type="text"
              onChange={() => setUpdated(updated + 1)}
              placeholder="0"
              ref={c3Val}
            />
          </div>
        </div>
        <div className="mfield field-4">
          <div className="shadow-md">
            <div className="font-semibold text-center border bg-stone-200">
              C4
            </div>
            <input
              className="p-2 border-0"
              type="text"
              onChange={() => setUpdated(updated + 1)}
              placeholder="0"
              ref={c4Val}
            />
          </div>
        </div>
        <div className="mfield field-5">
          <div className="shadow-md">
            <div className="font-semibold text-center border bg-stone-200">
              C5
            </div>
            <input
              className="p-2 border-0"
              type="text"
              onChange={() => setUpdated(updated + 1)}
              placeholder="0"
              ref={c5Val}
            />
          </div>
        </div>
        <div className="mfield field-6">
          <div className="shadow-md">
            <div
              className={`px-2 text-center border font-semibold uppercase ${totalBgColorClass}`}
            >
              Total
            </div>
            <div className="p-2 text-center bg-stone-100" ref={total}>
              {total.current.textContent}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CSide;
