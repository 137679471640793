import create from "zustand";
import { persist } from "zustand/middleware";

const useStore = create(
  persist(
    (set) => ({
      newModel: null,
      setNewModel: (value) => {
        set(() => ({ newModel: value }));
      },
      oldModel: null,
      setOldModel: (value) => {
        set(() => ({ oldModel: value }));
      },
      patternDetails: null,
      setPatternDetails: (value) => {
        set(() => ({ patternDetails: value }));
      },
      clearPatternDetails: () => {
        set(() => ({ patternDetails: null }));
      },
      adapterNeed: null,
      setAdapterNeed: (value) => {
        set(() => ({
          adapterNeed: value,
        }));
      },
      isCustom: false,
      setIsCustom: (value) => {
        set(() => ({ isCustom: value }));
      },
      cSide: null,
      setCside: (value) => {
        set(() => ({ cSide: value }));
      },
      mSide: null,
      setMside: (value) => {
        set(() => ({ mSide: value }));
      },
      sSide: null,
      setSside: (value) => {
        set(() => ({ sSide: value }));
      },
      isAdapterNeeded: null,
      setIsAdapterNeeded: (value) => {
        set(() => ({ isAdapterNeeded: value }));
      },
      isThereCurbAdapter: null,
      setIsThereCurbAdapter: (value) => {
        set(() => ({ isThereCurbAdapter: value }));
      },
      unitType: null,
      setUnitType: (value) => {
        set(() => ({ unitType: value }));
      },
      orientation: null,
      setOrientation: (value) => {
        set(() => ({ orientation: value }));
      },
      supplyLocation: null,
      setSupplyLocation: (value) => {
        set(() => ({ supplyLocation: value }));
      },
      quoteInfo: null,
      setQuoteInfo: (value) => {
        set(() => ({ quoteInfo: value }));
      },
      isAdvancedMode: false,
      setAdvancedMode: (value) => {
        set(() => ({ isAdvancedMode: value }));
      },
      noRtuModel: false,
      setNoRtuModel: (value) => {
        set(() => ({ noRtuModel: value }));
      },
      host: false,
      setHost: (value) => {
        set(() => ({ host: value }));
      },
      searchResults: [],
      setSearchResults: (value) => {
        set(() => ({ searchResults: value }));
      },
      searchKeyword: {}, // Search keyword for Length and Width search
      setSearchKeyword: (value) => {
        set(() => ({ searchKeyword: value }));
      },
      searchIndex: null,
      setSearchIndex: (index) => {
        set(() => ({ searchIndex: index }));
      },
      orientationIndex: 1,
      setOrientationIndex: (value) => {
        set(() => ({ orientationIndex: value }));
      },
      customLengthWidth: {},
      setCustomLengthWidth: (value) => {
        set(() => ({ customLengthWidth: value }));
      },
      isValidated: false,
      startOver: () => {
        set({}, true);
      },
      deleteEverything: () =>
        set({
          newModel: null,
          oldModel: null,
          patternDetails: null,
          adapterNeed: null,
          isCustom: false,
          cSide: null,
          mSide: null,
          sSide: null,
          isAdapterNeeded: null,
          isThereCurbAdapter: null,
          unitType: null,
          orientation: null,
          supplyLocation: null,
          quoteInfo: null,
          isAdvancedMode: false,
          noRtuModel: false,
          searchResults: [],
          searchKeyword: {},
          searchIndex: null,
          orientationIndex: 1,
          customLengthWidth: {},
        }),
    }),
    { name: "curbulator" }
  )
);

export default useStore;
