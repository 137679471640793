import React, { useState, useEffect } from "react";
import { lengthWidthSearch } from "../../anytime/api";
import useStore from "../../store/useStore";
import { useHotkeys } from "react-hotkeys-hook";
import { useForm } from "react-hook-form";

const SearchForm = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const { setSearchKeyword, setSearchResults, searchKeyword, host } = useStore(
    (state) => ({
      setSearchKeyword: state.setSearchKeyword,
      setSearchResults: state.setSearchResults,
      searchKeyword: state.searchKeyword,
      host: state.host,
    })
  );

  const [loading, setLoading] = useState(false);

  const doSearch = async (data) => {
    if (data.length !== "" || data.width !== "") {
      setLoading(true);

      await lengthWidthSearch(data.length, data.width, host.api).then(
        (response) => {
          setSearchResults(response);
          setLoading(false);
        }
      );

      setSearchKeyword({
        length: data.length,
        width: data.width,
      });
    }
  };

  useHotkeys("enter", doSearch);

  useEffect(() => {
    setValue("length", searchKeyword?.length);
    setValue("width", searchKeyword?.width);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword]);

  return (
    <>
      <form onSubmit={handleSubmit(doSearch)}>
        <div className="grid grid-cols-1 gap-4 p-4 border-b bg-stone-100 sm:grid-cols-4">
          <div>
            <input
              type="text"
              placeholder="Length"
              className="input-text"
              {...register("length", {
                pattern: {
                  value: /(\d*\.)?\d+/,
                },
              })}
            />
            {errors.length && (
              <span className="text-red-500 text-xs">Enter a valid length</span>
            )}
          </div>
          <div>
            <input
              type="text"
              placeholder="Width"
              className="input-text"
              {...register("width", {
                pattern: {
                  value: /(\d*\.)?\d+/,
                },
              })}
            />
            {errors.width && (
              <span className="text-red-500 text-xs">Enter a valid width</span>
            )}
          </div>
          <div>
            <div className="flex flex-col items-center sm:flex-row">
              <span className="mb-5 mr-0 sm:mr-5 sm:mb-0">OR</span>
              <input
                type="text"
                disabled
                className="input-text"
                placeholder="Label"
              />
            </div>
          </div>
          <div>
            <button className="w-full btn-primary" disabled={loading}>
              {loading ? "Searching..." : "Search"}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default SearchForm;
