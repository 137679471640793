import create from "zustand";
import { persist } from "zustand/middleware";

const useLogin = create(
  persist(
    (set) => ({
      userInfo: null,
      setUserInfo: (value) => {
        set(() => ({ userInfo: value }));
      },
      deleteUserInfo: () => set({}, true),
    }),
    { name: "LoginDetails", getStorage: () => sessionStorage }
  )
);

export default useLogin;
