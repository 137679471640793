import React, { useEffect, useMemo } from "react";
import CurbMeasurements from "./components/measurements/CurbMeasurements";
import Login from "./components/Login";
import CreateProject from "./components/create-project/CreateProject";
import CreateProjectSuccess from "./components/create-project/CreateProjectSuccess";
import TopMenu from "./components/menu/TopMenu";
import useStore from "./store/useStore";
import Distributors from "./clients/Distributors";
import Search from "./components/search/Search";
import Home from "./components/Home";
import dayjs from "dayjs";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SearchDetail from "./components/search/SearchDetail";

function App() {
  const year = dayjs().year();
  const { host, setHost } = useStore((state) => ({
    host: state.host,
    setHost: state.setHost,
  }));

  const distributors = useMemo(Distributors, []);

  useEffect(() => {
    let isCancelled = false;

    const referrer =
      window.location !== window.parent.location
        ? document.referrer
        : document.location.href;

    if (referrer && !host) {
      for (let host in distributors) {
        if (referrer.includes(host)) {
          return setHost(distributors[host]);
        }
      }
      if (!isCancelled) {
        setHost(distributors.default);
      }
    }

    if (!isCancelled) {
      // Refresh the page when service worker is updated
      if (navigator.serviceWorker?.controller?.onstatechange) {
        console.log("[App] Service worker updated");
        window.location.reload();
      }
    }
    return () => {
      isCancelled = true;
    };
  }, [setHost, distributors, host]);

  return null;

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: `<!-- © ${year} One Into Many, LLC. All rights reserved. -->`,
        }}
      />
      <Router>
        <TopMenu />
        <div id="curbulator-content" className="container">
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/curb-configure" element={<CurbMeasurements />} />
            <Route path="/create-project" element={<CreateProject />} />
            <Route
              path="/create-project-success"
              element={<CreateProjectSuccess />}
            />
            <Route path="/search" exact element={<Search />} />
            <Route path="/search/details/:id" element={<SearchDetail />} />
            <Route path="/login" element={<Login />} />
          </Routes>
          <div className="mt-20 text-sm text-center text-gray-100">
            &copy; {year} One Into Many, LLC. All rights reserved.
          </div>
        </div>
      </Router>
    </>
  );
}

export default App;
